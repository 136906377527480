<template>
    <button
        type="button"
        class="btn btn-pdv btn-pdv-red mr-1"
        href="javascript: void(0);"
        :disabled="busy || disabled"
        @click="$emit('onClick')"
    >
        <template v-if="busy">
            <b-spinner small />
        </template>
        <template v-else>
            <i class="fe-trash" />
            {{ getI18n('COMMON', 'delete_all') }}
        </template>
    </button>
</template>

<script>
export default {
    props: {
        busy: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
}
</script>

