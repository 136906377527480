<template>
    <form-modal ref="formModal" :title="titleFormatted" size="xl">
        <template v-slot:form>
            <form>
                <div class="row">
                    <div class="col-sm-6 form-inline">
                        <filter-bar v-model="filterText"></filter-bar>
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <slot></slot>
                        </div>
                    </div>
                </div>
                <b-table
                    hover
                    :fields="fields"
                    :items="itemsComp"
                    :busy="loading"
                    :filter="filterText"
                    :current-page="currentPage"
                    :per-page="perPage"
                >
                    <template v-slot:table-busy>
                        <b-skeleton-table
                        :rows="4"
                        :columns="fields.length"
                        :table-props="{
                            hover: true,
                            noBorderCollapse: true,
                        }"
                        />
                    </template>
                </b-table>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="itemsComp.length"
                    :per-page="perPage"
                    align="right"
                    variant="danger"
                />
            </form>
        </template>
    </form-modal>
</template>

<script>
import FormModal from '@/src/components/FormModal.vue'
import FilterBar from '@src/components/FilterBar'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import BusinessUnitsService from '@/src/services/BusinessUnitsService'

const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        FilterBar,
    },
    mixins: [
        vuetableFormatters,
    ],
    data() {
        return {
            items: [],
            currentPage: 1,
            perPage: 10,
            businessUnitId: 0,
            businessUnitName: '',
            itemsModal: this.items,
            titleModal: this.title,
            filterText: null,
            loading: false,
            fields:
            [
                {
                    key: 'name',
                    label: this.getI18n(i18nCommon, 'name'),
                    sortable: true,
                },
                {
                    key: 'email',
                    label: this.getI18n(i18nCommon, 'email'),
                    sortable: true,
                },
                {
                    key: 'role.0.alias',
                    label: this.getI18n(i18nCommon, 'role'),
                    sortable: true,
                },
                {
                    key: 'long_name',
                    label: this.getI18n(i18nCommon, 'address'),
                    sortable: true,
                },
                {
                    key: 'timezone',
                    label: this.getI18n(i18nCommon, 'timezone'),
                    sortable: true,
                    formatter: this.formatTimezone,
                },
                {
                    key: 'active',
                    label: this.getI18n(i18nCommon, 'active'),
                    sortable: true,
                    formatter: this.booleanIcons
                },
                {
                    key: 'monthly_cost',
                    label: this.getI18n('USERS', 'FIELDS.monthly_cost'),
                    sortable: true,
                    formatter: this.getI18nCurrency,
                },
                {
                    key: 'hours_daily',
                    label: this.getI18n('USERS', 'FIELDS.hours_daily'),
                    sortable: true,
                    sortField: 'hours_daily',
                    formatter: this.formatHours,
                },
            ],
        }
    },
    computed: {
        i18nUsers() {
            return this.getI18nModified({
                prefix: 'USERS',
                suffix: 'TITLES.user',
                modifier: 2,
            })
        },
        titleFormatted() {
            if (this.businessUnitName) {
                return `${this.i18nUsers} (${this.businessUnitName})`
            } else {
                return this.i18nUsers
            }
        },
        itemsComp: {
            get() {
                return this.items
            },
            set(value) {
                this.$emit('items', value)
            },
        },
    },
    methods: {
        fetch() {
            this.loading = true
            BusinessUnitsService.fetchById(this.businessUnitId, {
                with: ['users']
            }).then(response => {
                this.items = response.data.data.users
                this.loading = false
            })
            .catch(() => {
                this.items = []
                this.loading = false
            })
        },
        showModal(id, name) {
            this.businessUnitId = id
            this.businessUnitName = name
            this.fetch()
            this.$nextTick(() => {
                this.$refs.formModal.show()
            })
        },
        hideModal() {
            this.$refs.formModal.hide()
        },
    },
}
</script>
