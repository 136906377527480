<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nCommon = 'COMMON'
const i18nKey = 'BUSINESS_UNITS'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            businessUnitFields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'name',
                },
                {
                    name: 'contract_value',
                    title: this.getI18n(i18nKey, 'TITLES.contract_value'),
                    sortField: 'contract_value',
                    formatter: this.getI18nCurrency,
                },
                {
                    name: 'contract_time',
                    title: this.getI18n(i18nKey, 'TITLES.contract_time'),
                    sortField: 'contract_time',
                },
                {
                    name: 'point_of_sales',
                    title: this.getI18nModified({
                        prefix: 'POS',
                        suffix: 'TITLES.pos',
                        modifier: 2,
                    }),
                },
                {
                    name: 'users',
                    title: this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.user',
                        modifier: 2,
                    }),
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
        }
    },
}
</script>
