<template>
    <data-modal
        ref="dataModal"
        :fetch-data="fetch"
        :title="titleFormatted"
        :fields="fields"
        :additional-parameters="additionalParameters"
        :sort-order="sortOrder"
        :search-fields="searchFields"
        :per-page="perPage"
        size="xl"
    >
    </data-modal>
</template>

<script>
import DataModal from '@/src/components/dataModal'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import posFields from '@src/fields/posFields.vue'
import PosService from '@/src/services/PosService'

export default {
    components: {
        DataModal,
    },
    mixins: [
        vuetableFormatters,
        posFields
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            data: [],
            perPage: 10,
            businessUnitId: 0,
            businessUnitName: '',
        }
    },
    computed: {
        fields() {
            return this.posFields.filter(
                (field) => (field.name !== 'actions')  && (field.name !== 'business_units') && (typeof field.name !== 'object')
            )
        },
        additionalParameters() {
            return {
                with: [
                    'chain',
                    'channel',
                    'category',
                    'city',
                    'state',
                    'regional',
                    'flag',
                ],
                business_unit_id: [this.businessUnitId],
            }
        },
        searchFields() {
            return [
                'name',
                'address',
                'channel.name',
                'category.name',
                'chain.name',
                'regional.name',
                'flag.name'
            ]
        },
        i18nPos() {
            return this.getI18nModified({
                prefix: 'POS',
                suffix: 'TITLES.pos',
                modifier: 2,
            })
        },
        titleFormatted() {
            if (this.businessUnitName) {
                return `${this.i18nPos} (${this.businessUnitName})`
            } else {
                return this.i18nPos
            }
        },
    },
    methods: {
        fetch(url, params) {
            return PosService.fetchVuetable(url, params)
        },
        showModal(id, name) {
            this.businessUnitId = id
            this.businessUnitName = name
            this.$nextTick(() => {
                this.$refs.dataModal.show()
            })
        },
        hideModal() {
            this.$refs.dataModal.hide()
        },
    },
}
</script>
