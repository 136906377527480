<template>
    <form-modal
        id="dataModal"
        ref="modal"
        :title="importTitle"
        :in-editing="(step !== 1)"
        :size="step === 1 ? 'md' : 'xl'"
        :handle-hide="cleanModal"
        :handle-open="resetValidation"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <confirmation-form-modal
                ref="confirmationFormModal"
                :title="importTitle"
                :operation="'import'"
            />
            <div v-if="step === 1 || !csvValid">
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group
                                :label="i18nFileHasHeader"
                                label-for="has-header"
                                class="label-pdv">
                                <b-form-checkbox
                                    id="has-header"
                                    v-model="csv.has_headers"
                                    size="lg"
                                    switch
                                />
                            </b-form-group>
                        </b-col>
                        <b-col v-if="csvType">
                            <b-form-group
                                :label="i18nSpreadsheetTemplate"
                                label-for="template-download"
                                class="label-pdv">
                                <a :href="`files/importJob/${csvType}.zip`" download>
                                    <i class="fe-download mr-1" />
                                    {{ i18nDownloadFile }}
                                </a>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group
                                :label="i18nFile"
                                label-for="csv"
                                class="required label-pdv">
                                <div class="input-bar d-flex">
                                    <b-form-file
                                        ref="csv"
                                        v-model="$v.csv.file.$model"
                                        required
                                        type="file"
                                        :accept="fileMimeTypes.toString()"
                                        :browse-text="getI18n('COMMON', 'browse')"
                                        :placeholder="getI18n('COMMON', 'no_file_chosen')"
                                        :state="validateField('file', 'csv')"
                                        @blur="$v.csv.file.$touch"
                                        @input="fileChange"
                                    />
                                </div>
                                <b-form-invalid-feedback :state="validateField('file', 'csv')">
                                    {{ getI18n('ERROR_CODES', 'invalid_file') }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
            <div v-if="step === 2 && csvValid">
                <field-map
                    ref="fieldMap"
                    v-model="csv.fields_mapped"
                    :first-row="csv.preview[0]"
                    :fields-to-map="csv.fields"
                ></field-map>
            </div>
            <div v-if="step === 3 && csvValid">
                <div class="d-flex">
                    <h4>{{ i18nPreview }}</h4>
                    <p style="margin: auto 5px">({{ i18nTenLines }})</p>
                </div>
                <div class="table-responsive">
                    <vuetable
                        ref="mapTable"
                        :fields="fields"
                        :api-mode="false"
                        :data="mappedPreview"
                        class="table table-centered table-hover mb-0"
                    >
                        <template slot="row" slot-scope="props">{{
                            props.rowIndex + 1
                        }}</template>
                    </vuetable>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div v-if="step === 1">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    @onClick="hideModal"
                />
                <save-button
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    @onClick="goToMapping"
                />
            </div>
            <div v-else-if="step === 2 && csvValid">
                <back-button
                    ref="backButton"
                    child-class="col-sm-4 float-left"
                    :disabled="loadingSecondStep"
                    @onClick="goToForm"
                />
                <save-button
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    :busy="loadingSecondStep"
                    @onClick="buildCsv"
                />
            </div>
            <div v-else-if="step === 3 && csvValid">
                <save-button
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON', 'import')"
                    :busy="loadingThirdStep"
                    @onClick="csvCreated"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import BaseService from '../services/BaseService'

import { required } from 'vuelidate/lib/validators'
import mimeTypes from 'mime-types'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import BackButton from '@/src/components/BackButton'
import CancelButton from '@/src/components/CancelButton'
import SaveButton from '@/src/components/SaveButton'
import csvFields from '@src/mixins/csvFields'
import validation from '@src/mixins/validation'
import FormModal from '@components/FormModal'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import FieldMap from '@src/components/FieldMap.vue'
import csvParse from '@src/mixins/csvParse'
import filterVuetable from '@src/mixins/filterVuetable'

const i18nCommon = 'COMMON'
const i18nKey = 'IMPORT_JOBS'

export default {
    components: {
        Vuetable,
        FormModal,
        ConfirmationFormModal,
        FieldMap,
        BackButton,
        CancelButton,
        SaveButton,
    },
    mixins: [
        csvFields,
        validation,
        vuetableFormatters,
        swalFeedback,
        csvParse,
        filterVuetable,
    ],
    props: {
        csvType: {
            type: String,
            required: true,
        },
        typeFields: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        service: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            csv: {
                file: null,
                headers: null,
                has_headers: true,
                delimiter: ',',
                fields: {},
                parsed: {},
                preview: {},
                fields_mapped: {},
            },
            mappedPreview: null,
            fileMimeTypes: [
                'text/csv',
                'text/x-csv',
                'application/vnd.ms-excel',
                'text/plain',
            ],
            step: 1,
            size: 'md',
            loadingSecondStep: false,
            loadingThirdStep: false,
            fields: null,
        }
    },
    validations: {
        csv: {
            file: {
                required,
                isValidMime(value) {
                    if (!value) {
                        return true
                    }
                    const file = value

                    const mimeType =
                        file.type === ''
                            ? mimeTypes.lookup(file.name)
                            : file.type

                    return this.fileMimeTypes.indexOf(mimeType) > -1
                },
                isValidFileSize(value) {
                    if (!value) {
                        return true
                    }
                    const fileSizeinKb =
                        Math.round((value.size / 1024) * 100) / 100
                    return fileSizeinKb <= 5000
                },
            },
        },
    },
    computed: {
        importTitle() {
            return this.title || this.getI18n(i18nKey, 'TITLES.import_job')
        },
        i18nFileHasHeader() {
            return this.getI18n('IMPORT_JOBS', 'TITLES.has_header')
        },
        i18nImportType() {
            return this.getI18n(i18nKey, 'TITLES.import_type')
        },
        i18nFile() {
            return `${this.getI18n(i18nCommon, 'file')} (${this.i18nAllowedFormats})`
        },
        csvValid() {
            return !this.$v.csv.$invalid
        },
        i18nPreview() {
            return this.getI18n(i18nCommon, 'preview')
        },
        i18nTenLines() {
            return this.getI18n(i18nKey, 'TITLES.ten_lines')
        },
        i18nAllowedFormats() {
            return this.getI18n(i18nKey, 'TITLES.allowed_formats')
        },
        i18nSpreadsheetTemplate() {
            return this.getI18n(i18nCommon, 'spreadsheet_template')
        },
        i18nDownloadFile() {
            return `${this.getI18n(i18nCommon, 'download')} ${this.getI18n(i18nCommon, 'file')}`
        },
        serviceClass() {
            return new BaseService(this.service)
        },
    },
    methods: {
        async goToMapping() {
            this.$v.csv.$touch()

            if (this.csvValid) {
                this.fields = Object.keys(this.typeFields).map((key, index) => {
                    return {
                        name: key,
                        title: this.typeFields[key].label,
                    }
                })
                this.fields.unshift({
                    name: 'row',
                    title: this.getI18n('COMMON', 'line'),
                })

                this.csv.fields = this.createFieldsToMap(this.typeFields)
                this.loadCsv(this.csv.file).then(
                    ({ csvPreview, fullCsv, delimiter }) => {
                        this.csv.delimiter = delimiter
                        this.csv.preview = csvPreview
                        this.csv.parsed = fullCsv
                        this.mapByName(
                            this.csv.preview[0],
                            this.csv.fields,
                            this.csv.fields_mapped
                        )
                        this.step = 2
                    }
                )
            }
        },
        setLoadingThirdStep(value) {
            this.loadingThirdStep = value
        },
        goToForm() {
            this.cleanModal()
            this.step = 1
        },
        async csvCreated() {
            this.csv.type = this.csvType
            this.$emit('csvCreated', this.csv)
        },
        buildCsv() {
            this.loadingSecondStep = true
            if (this.$refs.fieldMap.validateForm()) {
                this.mappedPreview = this.mapJson(
                    this.csv.preview,
                    this.csv.fields_mapped,
                    this.csv.has_headers
                )
                this.step = 3
            }
            this.loadingSecondStep = false
        },
        fileChange() {
            if (this.$refs.fieldMap) {
                this.$refs.fieldMap.refreshTable()
            }
        },
        cleanFile() {
            this.csv.file = null
            setTimeout(() => {
                this.resetValidation()
            }, 0)
        },
        showModal() {
            this.cleanModal()
            this.$refs.modal.show()
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },

        cleanModal() {
            this.step = 1
            this.mappedPreview = null
            this.loadingThirdStep = false
            this.loadingSecondStep = false
            Object.assign(this.csv, {
                delimiter: ',',
                file: null,
                headers: null,
                type: null,
                has_headers: true,
                preview: {},
                parsed: {},
                fields: {},
                fields_mapped: {},
            })
            this.resetValidation()
        },

        hideModal() {
            this.$refs.modal.hide()
        },

        handleCancel() {
            if (this.step > 1) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },

        handleClose(close) {
            if (!close) {
                return
            }

            this.cleanModal()
            this.resetValidation()
            this.hideModal()
        },

        async fetchRelationItems(csv, params = {}) {
            this.setLoadingThirdStep(true)
            const items = this.mapJson(
                csv.parsed,
                csv.fields_mapped,
                csv.has_headers
            )
            const param = Object.keys(this.selectFields[this.csvType])[0]

            const parameters = this.formatParameters({
                ...params,
                [param]: items.map((item) => item[param]),
                pagination: false,
            })

            const response = await this.serviceClass
                .fetchAll(parameters)
                .then((response) => response)
                .catch((err) => err)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                return response.data.data
            }

            this.negativeFeedback()
            this.setLoadingThirdStep(false)
            return []
        },
    },
}
</script>
