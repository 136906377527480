<script>
import VuetableFieldCheckbox from '@src/components/vuetable/VuetableFieldCheckbox.vue'
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nCommon = 'COMMON'
const i18nKey = 'POS'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            posFields: [
                {
                    name: VuetableFieldCheckbox,
                    titleClass: 'center aligned',
                    dataClass: 'center aligned',
                    togglable: true,
                },
                {
                    name: 'external_code',
                    title: this.getI18n(i18nCommon, 'external_code'),
                    sortField: 'external_code',
                },
                {
                    name: 'name',
                    title: this.getI18n(i18nKey, 'TITLES.pos'),
                    sortField: 'name',
                },
                {
                    name: 'channel.name',
                    title: this.getI18n(i18nKey, 'TITLES.channel'),
                    sortField:
                        'point_of_sales_channels:channel_id|point_of_sales_channels.name',
                },
                {
                    name: 'chain.name',
                    title: this.getI18n(i18nKey, 'TITLES.chain'),
                    sortField:
                        'point_of_sales_chains:chain_id|point_of_sales_chains.name',
                },
                {
                    name: 'long_name',
                    title: this.getI18n(i18nCommon, 'address'),
                    sortField: 'long_name',
                },
                {
                    name: 'regional.name',
                    title: this.getI18n(i18nKey, 'TITLES.regional'),
                    sortField:
                        'point_of_sales_regionals:regional_id|point_of_sales_regionals.name',
                },
                {
                    name: 'flag.name',
                    title: this.getI18n(i18nKey, 'TITLES.flag'),
                    sortField:
                        'point_of_sales_flags:flag_id|point_of_sales_flags.name',
                },
                {
                    name: 'category.name',
                    title: this.getI18n(i18nKey, 'TITLES.category'),
                    sortField:
                        'point_of_sales_categories:category_id|point_of_sales_categories.name',
                },
                {
                    name: 'business_units',
                    title: this.getI18nModified({
                        prefix: 'BUSINESS_UNITS',
                        suffix: 'TITLES.business_unit',
                        modifier: 2,
                    }),
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
        }
    },
}
</script>
