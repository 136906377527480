<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import Layout from '@layouts/main.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import BusinessUnitsService from '@src/services/BusinessUnitsService'
import Pagination from '@src/mixins/pagination.vue'
import Shared from '@src/mixins/shared.vue'
import swalFeedback from '@src/mixins/swalFeedback'
import businessUnitModal from '@src/router/views/businessUnit/businessUnitModal'
import businessUnitPosModal from '@views/businessUnit/businessUnitPosModal'
import businessUnitUserModal from '@views/businessUnit/businessUnitUserModal'
import validationMixin from '@src/mixins/validation.vue'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import FilterBar from '@src/components/FilterBar'
import CountTableButton from '@/src/components/CountTableButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import userChildrenFields from '@/src/fields/childrens/userChildrenFields.vue'
import posChildrenFields from '@/src/fields/childrens/posChildrenFields.vue'
import businessUnitFields from '@/src/fields/businessUnitFields.vue'
import ImportButton from '@/src/components/ImportButton.vue'
import importModal from '@views/importJob/importJobModal'
import { debounce } from 'lodash'

const i18nCommon = 'COMMON'
const i18nKey = 'BUSINESS_UNITS'

export default {
    metaInfo() {
        return {
            title: this.i18nBusinessUnit(2),
        }
    },
    components: {
        ApiModeVuetable,
        Layout,
        Breadcrumb,
        PageHeader,
        businessUnitModal,
        businessUnitPosModal,
        businessUnitUserModal,
        FilterBar,
        AddLabelButton,
        ExportButton,
        CountTableButton,
        MoreTableButton,
        importModal,
        ImportButton
    },
    mixins: [
        Pagination,
        Shared,
        swalFeedback,
        validationMixin,
        vuetableFormatters,
        userChildrenFields,
        posChildrenFields,
        businessUnitFields
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon,
            i18nKey,
            modalIsEdit: false,
            modal: {
                id: '',
                name: '',
                contract_value: 0,
                contract_time: 0,
                add_all_users: false,
                add_all_pos: false,
                users: [],
                point_of_sales: [],
                justification_types: [],
            },
            additionalParameters: {
                with: ['pointOfSales', 'users'],
            },
            data: [],
            perPage: 10,
            css: {},
            filterText: '',
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'POS',
                        suffix: 'TITLES.pos',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.business_unit',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nNewBusinessUnit() {
            return this.getI18n(i18nKey, 'BUTTONS.new_business_unit')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters.search = ''
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        fetch(url, params) {
            return BusinessUnitsService.fetchVuetable(url, params)
        },
        createAdditionalParameters() {
            this.additionalParameters.search = ''
            if (this.filterText && this.filterText.length > 0) {
                this.additionalParameters.search = `name:${this.filterText}`
            }
            return this.additionalParameters
        },
        i18nBusinessUnit(modifier) {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.business_unit',
                modifier,
            })
        },
        cleanModal() {
            this.replaceModalData({
                id: '',
                name: '',
                contract_value: 0,
                contract_time: 0,
                users: [],
                point_of_sales: [],
            })
        },
        showModal() {
            this.$nextTick(() => this.$refs.businessUnitModal.showModal())
        },
        openModalToCreate() {
            this.modalIsEdit = true
            this.cleanModal()
            this.showModal()
        },
        openModalToEdit(unity) {
            this.modalIsEdit = false
            this.replaceModalData(unity)
            this.showModal()
        },
        openModalUsers(data) {
            this.$nextTick(() => this.$refs.businessUnitUserModal.showModal(data.id, data.name))
        },
        openModalPos(data) {
            this.$nextTick(() => this.$refs.businessUnitPosModal.showModal(data.id, data.name))
        },
        replaceModalData(data) {
            Object.assign(this.modal, data)
        },
        reloadTable() {
            this.$refs.vuetable.reload()
        },
        refreshTable() {
            this.$refs.vuetable.refresh()
        },
        showImportModal() {
            this.$nextTick(() => this.$refs.importModal.showModal())
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText"></filter-bar>
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'BUSINESSUNIT')"
                            :title="i18nNewBusinessUnit"
                            @onClick="openModalToCreate"
                        />
                        <import-button
                            v-if="$can('CREATE', 'IMPORTJOB')"
                            @onClick="showImportModal"
                        />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="business_unit"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nBusinessUnit(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="businessUnitFields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="users" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.users_count"
                                @onClick="openModalUsers(props.rowData)"
                            />
                        </template>
                        <template slot="point_of_sales" slot-scope="props">
                            <count-table-button
                                :value="props.rowData.points_of_sale_count"
                                @onClick="openModalPos(props.rowData)"
                            />
                        </template>
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button @onClick="openModalToEdit(props.rowData)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <business-unit-modal
            ref="businessUnitModal"
            :modal="modal"
            :modal-is-edit="modalIsEdit"
            @refresh="reloadTable"
        />
        <business-unit-user-modal
            ref="businessUnitUserModal"
        />
        <business-unit-pos-modal
            ref="businessUnitPosModal"
        />
        <import-modal
            ref="importModal"
            :csv-type="'business_unit'"
            :title="
                getI18nModified({
                    prefix: 'BUSINESS_UNITS',
                    suffix: 'TITLES.business_unit',
                    modifier: 2,
                })
            "
        />
    </Layout>
</template>
