<template>
    <form-modal
        ref="formModal"
        :v="$v.modalBusinessUnit"
        :title="i18nTitleModal"
        :handle-hide="cleanModal"
        :in-editing="isEdit"
        :size="tabIndex === 0 ? 'lg' : 'xl'"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nBusinessUnit"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nBusinessUnit"
                />
                <selectCsvModal
                    ref="posCsvModal"
                    :title="i18nSelectThroughCsv"
                    :service="'pos'"
                    csv-type="point_of_sales_external_code"
                    :type-fields="selectFields.point_of_sales_external_code"
                    @csvCreated="loadPosByJson"
                />
                <selectCsvModal
                    ref="userCsvModal"
                    :title="i18nSelectThroughCsv"
                    :service="'users'"
                    csv-type="users_email"
                    :type-fields="selectFields.users_email"
                    @csvCreated="loadUsersByJson"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'BUSINESSUNIT')"
                                :title="getI18n(i18nKey, 'TITLES.business_unit')"
                                :busy="editLoading"
                                :disabled="deleteLoading"
                                @onClick="handleEdit"
                            />
                           <delete-label-button
                                v-if="$can('DELETE', 'BUSINESSUNIT')"
                                :title="getI18n(i18nKey, 'TITLES.business_unit')"
                                :busy="deleteLoading"
                                :disabled="editLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-tabs
                    id="businessunit-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv"
                    @activate-tab="tabActivated"
                >
                    <b-tab :title="i18nInformation">
                        <b-row>
                            <b-col md="12">
                                <b-form-group
                                    :label="i18nName"
                                    label-for="modal-name"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_name')"
                                >
                                    <b-form-input
                                        id="modal-name"
                                        v-model.trim="$v.modalBusinessUnit.name.$model"
                                        class="input-pdv-blue"
                                        name="name"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        :disabled="!isEdit || submitLoading"
                                        :state="validateField('name', 'modalBusinessUnit')"
                                        :placeholder="i18nName"
                                        autofocus
                                        autocomplete="off"
                                        @input="clearResponseError('name', 'modalBusinessUnit')"
                                        @blur="$v.modalBusinessUnit.name.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nContractValue"
                                    label-for="modal-contract-value"
                                    class="label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_contract_value')"
                                >
                                    <money
                                        id="modal-contract-value"
                                        v-model.trim="$v.modalBusinessUnit.contract_value.$model"
                                        v-bind="money"
                                        name="contract_value"
                                        class="form-control input-pdv-blue"
                                        :class="validationClass($v.modalBusinessUnit.contract_value)"
                                        aria-describedby="input-1-live-feedback"
                                        :disabled="!isEdit || submitLoading"
                                        type="text"
                                        :state="validateField('contract_value','modalBusinessUnit')"
                                        @blur.native="$v.modalBusinessUnit.contract_value.$touch;"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nContractTime"
                                    label-for="modal-contract-time"
                                    class="label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_contract_time')"
                                >
                                    <b-form-input
                                        id="modal-contract-time"
                                        v-model.trim="$v.modalBusinessUnit.contract_time.$model"
                                        name="contract_time"
                                        aria-describedby="input-1-live-feedback"
                                        type="number"
                                        min="0"
                                        class="input-pdv-blue"
                                        :state="validateField('contract_time','modalBusinessUnit')"
                                        :disabled="!isEdit || submitLoading"
                                        @input="clearResponseError('contract_time','modalBusinessUnit')"
                                        @blur="$v.modalBusinessUnit.contract_time.$touch;"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <b-spinner
                                v-if="loadingPos"
                                class="mr-1"
                                type="border"
                                small
                            />
                            {{ i18nPos }}
                        </template>
                        <b-form-row v-if="isEdit" class="d-flex justify-content-end">
                            <import-button
                                :disabled="!posLoaded || loadingPos || submitLoading"
                                @onClick="showPosCsvModal"
                            />
                            <delete-all-button
                                :disabled="!posLoaded || loadingPos || submitLoading"
                                @onClick="handleRemoveAllPos"
                            />
                        </b-form-row>
                        <b-form-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nPos"
                                    label-for="add-pos-input"
                                    class="label-pdv" >
                                    <multiSelectWithService
                                        :id="'add-pos-input'"
                                        ref="posMultiselect"
                                        v-model="posName"
                                        :service="'pos'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="!posLoaded || loadingPos || modalBusinessUnit.add_all_pos || submitLoading"
                                    />
                                </b-form-group>
                                <b-form-group
                                    class="label-pdv"
                                    :label="i18nAddAllPos"
                                    label-for="add-all-pos"
                                >
                                    <b-form-checkbox
                                        id="add-all-pos"
                                        v-model="modalBusinessUnit.add_all_pos"
                                        size="lg"
                                        switch
                                        :disabled="!posLoaded || loadingPos || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <data-mode-vuetable
                                    ref="relationVuetable"
                                    v-model="modalBusinessUnit.point_of_sales"
                                    :fields="posFields"
                                    :display-delete-button="isEdit"
                                    :busy="loadingPos"
                                    :disabled="submitLoading"
                                    @onClickDelete="handleRemovePos"
                                />
                            </b-col>
                        </b-form-row>
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <b-spinner
                                v-if="loadingUsers"
                                class="mr-1"
                                type="border"
                                small
                            />
                            {{ i18nUsers }}
                        </template>
                        <b-form-row>
                            <b-col md="12">
                                <b-alert show variant="info">
                                    <i class="fe-info icon-blue mr-1" />
                                    {{ i18nServiceCostModuleInfo }}.
                                </b-alert>
                            </b-col>
                        </b-form-row>
                        <b-form-row v-if="isEdit" class="d-flex justify-content-end">
                            <import-button
                                :disabled="!usersLoaded || loadingUsers || submitLoading"
                                @onClick="showUserCsvModal"
                            />
                            <delete-all-button
                                :disabled="!usersLoaded || loadingUsers || submitLoading"
                                @onClick="handleRemoveAllUser"
                            />
                        </b-form-row>
                        <b-form-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nUsers"
                                    label-for="modal-user-user"
                                    class="required label-pdv">
                                    <multiSelectWithService
                                        :id="'modal-user-user'"
                                        ref="userMultiselect"
                                        v-model="userModal.user"
                                        :input-function="$v.userModal.user.id.$touch"
                                        :select-class="validationClass($v.userModal.user.id)"
                                        :service="'users'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="!usersLoaded || loadingUsers || modalBusinessUnit.add_all_users || submitLoading"
                                        :parameters="usersParameters"
                                    />
                                </b-form-group>
                                <b-form-group
                                    :label="i18nMonthlyCost"
                                    label-for="modal-user-monthly-cost"
                                    class="label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_contract_value')"
                                >
                                    <money
                                        id="modal-user-monthly-cost"
                                        v-model.trim="userModal.monthly_cost"
                                        v-bind="money"
                                        name="monthly-cost"
                                        class="form-control input-pdv-blue"
                                        :class="validationClass($v.userModal.monthly_cost)"
                                        aria-describedby="input-1-live-feedback"
                                        :disabled="!usersLoaded || loadingUsers || modalBusinessUnit.add_all_users || submitLoading"
                                        :state="validateField('monthly_cost', 'userModal')"
                                    />
                                </b-form-group>
                                <b-form-group
                                    :label="i18nHoursDaily"
                                    label-for="modal-user-hours_daily"
                                    class="label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_contract_time')"
                                >
                                    <b-form-input
                                        id="modal-user-hours_daily"
                                        v-model.trim="$v.userModal.hours_daily.$model"
                                        name="contract_time"
                                        aria-describedby="input-1-live-feedback"
                                        type="number"
                                        min="0"
                                        class="input-pdv-blue"
                                        :state="validateField('hours_daily','userModal')"
                                        :disabled="!usersLoaded || loadingUsers || modalBusinessUnit.add_all_users || submitLoading"
                                        @input="clearResponseError('hours_daily','userModal')"
                                        @blur="$v.userModal.contract_time.$touch;"
                                    />
                                </b-form-group>
                                <add-button
                                    ref="addUserButton"
                                    :disabled="!usersLoaded || loadingUsers || modalBusinessUnit.add_all_users || submitLoading"
                                    @onClick="handleAddUser"
                                />
                                <b-form-group
                                    class="label-pdv mt-3"
                                    :label="i18nAddAllUser"
                                    label-for="add-all-users"
                                >
                                    <b-form-checkbox
                                        id="add-all-users"
                                        v-model="modalBusinessUnit.add_all_users"
                                        size="lg"
                                        switch
                                        :disabled="!usersLoaded || loadingUsers || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <data-mode-vuetable
                                    ref="relationVuetable"
                                    v-model="modalBusinessUnit.users"
                                    :fields="userFields"
                                    :display-delete-button="isEdit"
                                    :busy="loadingUsers"
                                    :disabled="submitLoading"
                                    @onClickDelete="handleRemoveUser"
                                />
                            </b-col>
                        </b-form-row>
                    </b-tab>
                </b-tabs>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="tabIndex !== 2"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    :busy="submitLoading"
                    @onClick="tabIndex++"
                />
                <save-button
                    v-else-if="isBusinessUnitCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    :disabled="!posLoaded || !usersLoaded"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    :disabled="!posLoaded || !usersLoaded"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>
<script>
import { Money } from 'v-money'

import FormModal from '@components/FormModal'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import SelectCsvModal from '@src/components/SelectCsvModal'
import {
    required,
    integer,
    decimal,
    minLength,
    maxLength,
    minValue,
} from 'vuelidate/lib/validators'
import validationMixin from '@src/mixins/validation'
import swalFeedback from '@src/mixins/swalFeedback'
import csvFields from '@src/mixins/csvFields'
import BusinessUnitsService from '@src/services/BusinessUnitsService'
import PosService from '@src/services/PosService'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import ImportButton from '@/src/components/ImportButton.vue'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import DeleteAllButton from '@/src/components/DeleteAllButton.vue'
import DataModeVuetable from '@/src/components/DataModeVuetable.vue'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import { i18n } from '@src/i18n'
import { unionBy } from 'lodash'

const i18nCommon = 'COMMON'
const i18nKey = 'BUSINESS_UNITS'

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        FeedbackModal,
        SelectCsvModal,
        Money,
        CancelButton,
        AddButton,
        ImportButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton,
        DeleteAllButton,
        DataModeVuetable,
        multiSelectWithService
    },
    mixins: [
        validationMixin,
        swalFeedback,
        csvFields,
        vuetableFormatters,
    ],
    props: {
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
        modal: {
            type: Object,
            default: () => {
                return {
                    id: '',
                    name: '',
                    contract_value: 0,
                    contract_time: 0,
                    add_all_users: false,
                    add_all_pos: false,
                    users: [],
                    point_of_sales: [],
                }
            },
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            modalBusinessUnit: this.modal,
            userModal: {},
            tabIndex: 0,
            isEdit: false,
            editLoading: false,
            submitLoading: false,
            deleteLoading: false,
            loadingUsers: false,
            loadingPos: false,
            usersLoaded: false,
            posLoaded: false,
            money: {
                decimal: i18n.t('CURRENCY.decimal'),
                thousands: i18n.t('CURRENCY.thousands'),
                prefix: i18n.t('CURRENCY.prefix'),
                suffix: i18n.t('CURRENCY.suffix'),
                precision: 2,
                masked: false,
            },
            posName: '',
            posFields: [
                {
                    key: 'external_code',
                    label: this.getI18n(i18nCommon, 'external_code'),
                    sortable: true,
                },
                {
                    key: 'name',
                    label: this.getI18n('POS', 'TITLES.pos'),
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
            userFields: [
                {
                    key: 'name',
                    label: this.getI18n(i18nCommon, 'name'),
                    sortable: true,
                },
                {
                    key: 'email',
                    label: this.getI18n(i18nCommon, 'email'),
                    sortable: true,
                },
                {
                    key: 'monthly_cost',
                    label: this.getI18n('USERS', 'FIELDS.monthly_cost'),
                    sortable: true,
                    formatter: this.getI18nCurrency,
                },
                {
                    key: 'hours_daily',
                    label: this.getI18n('USERS', 'FIELDS.hours_daily'),
                    sortable: true,
                    formatter: this.formatHours,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
        }
    },
    validations: {
        modalBusinessUnit: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },
            contract_value: {
                decimal,
                minValue: minValue(0),
            },
            contract_time: {
                integer,
                minValue: minValue(0),
            },
        },
        userModal: {
            user: {
                id: {
                    required,
                    integer,
                    minValue: minValue(1)
                },
            },
            monthly_cost: {
                decimal,
                minValue: minValue(0),
            },
            hours_daily: {
                integer,
                minValue: minValue(0)
            },
        }
    },
    computed: {
        isBusinessUnitCreated() {
            return this.modalBusinessUnit.id > 0
        },
        i18nTitleModal() {
            if (this.isBusinessUnitCreated) {
                if (this.isEdit) {
                    return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nBusinessUnit}`
                }
                return this.modalBusinessUnit.name
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_business_unit')
            }
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nKey, 'TITLES.business_unit')
        },
        i18nContractValue() {
            return this.getI18n(i18nKey, 'TITLES.contract_value')
        },
        i18nContractTime() {
            return this.getI18n(i18nKey, 'TITLES.contract_time')
        },
        i18nAddAllUser() {
            return this.getI18n(i18nKey, 'TITLES.add_all_user')
        },
        i18nAddAllPos() {
            return this.getI18n(i18nKey, 'TITLES.add_all_pos')
        },
        i18nSelectThroughCsv() {
            return this.getI18n('QUESTIONNAIRE_FILTERS', 'TITLES.select_through_csv')
        },
        i18nNoRecordsFound() {
            return this.getI18n('PAGINATION', 'no_records_found')
        },
        i18nMonthlyCost() {
            return this.getI18n('USERS', 'FIELDS.monthly_cost')
        },
        i18nHoursDaily() {
            return this.getI18n('USERS', 'FIELDS.hours_daily')
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: 'COMMON.info',
                modifier: 2,
            })
        },
        i18nUsers() {
            return this.getI18nModified({
                prefix: 'USERS',
                suffix: 'TITLES.user',
                modifier: 2,
            })
        },
        i18nPos() {
            return this.getI18nModified({
                prefix: 'POS',
                suffix: 'TITLES.pos',
                modifier: 2,
            })
        },
        i18nSuccessfullyProcessed() {
            return this.getI18nModified({
                prefix: 'RESPONSES',
                suffix: 'successfully_processed',
                modifier: 3,
            })
        },
        i18nServiceCostModuleInfo() {
            return this.getI18n(i18nKey, 'MESSAGES.service_cost_module_info')
        },
        modalOperation() {
            return this.isBusinessUnitCreated ? 'edited' : 'created'
        },
    },
    watch: {
        posName(value) {
            if (value) {
                const index = this.modalBusinessUnit.point_of_sales.findIndex(find => find.id === value.id);
                if (index < 0) {
                    this.modalBusinessUnit.point_of_sales.push(value)
                }
                this.posName = ''
                this.$refs.posMultiselect.$el.focus()
            }
        },
        'modalBusinessUnit.add_all_users': function (newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.modalBusinessUnit.users = []
            }
        },
        'modalBusinessUnit.add_all_pos': function (newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.modalBusinessUnit.point_of_sales = []
            }
        },
    },
    methods: {
        tabActivated(newTabIndex, oldTabIndex, event) {
            if (this.isEdit) {
                if (oldTabIndex === 0) {
                    this.$v.modalBusinessUnit.$touch()
                    if (this.$v.modalBusinessUnit.$invalid) {
                        event.preventDefault();
                    }
                }
            }
            if (newTabIndex === 1) {
                this.loadPos()
            } else if (newTabIndex === 2) {
                this.loadUsers()
            }
        },
        async loadUsers() {
            if (this.usersLoaded || this.loadingUsers) {
                return
            }

            if (!this.isBusinessUnitCreated) {
                this.usersLoaded = true
                return
            }

            this.loadingUsers = true
            await BusinessUnitsService.fetchById(this.modalBusinessUnit.id, {
                with: ['users'],
            }
            ).then((response) => {
                this.modalBusinessUnit.users = response.data.data.users
                this.usersLoaded = true
            })
            this.loadingUsers = false
        },
        async loadPos() {
            if (this.posLoaded || this.loadingPos) {
                return
            }

            if (!this.isBusinessUnitCreated) {
                this.posLoaded = true
                return
            }

            this.loadingPos = true
            await PosService.fetchAll({
                business_unit_id: [this.modalBusinessUnit.id],
                pagination: false,
            }).then((response) => {
                this.modalBusinessUnit.point_of_sales = response.data.data
                this.posLoaded = true
            })
            this.loadingPos = false
        },
        showModal() {
            this.tabIndex = 0
            this.isEdit = this.modalIsEdit
            this.submitLoading = false
            this.editLoading = false
            this.deleteLoading = false
            this.loadingUsers = false
            this.loadingPos = false
            this.usersLoaded = false
            this.posLoaded = false
            this.posName = ''
            this.modalBusinessUnit = this.modal
            this.modalBusinessUnit.add_all_users = false
            this.modalBusinessUnit.add_all_pos = false
            this.$refs.formModal.show()
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
        },

        cleanModal() {
            this.replaceModalData({
                id: '',
                name: '',
                contract_value: 0,
                contract_time: 0,
                add_all_users: false,
                add_all_pos: false,
                users: [],
                point_of_sales: [],
            })
            this.resetValidation()
        },

        replaceModalData(data) {
            Object.assign(this.modalBusinessUnit, data)
        },
        handleEdit() {
            this.editLoading = true

            this.loadUsers()
            this.loadPos()
            this.isEdit = true
            this.tabIndex = 0

            this.editLoading = false
        },
        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        handleOk() {
            this.$v.modalBusinessUnit.$touch()
            if (!this.$v.modalBusinessUnit.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        createOrUpdateBusinessUnit() {
            const operation = this.isBusinessUnitCreated
                ? BusinessUnitsService.update(this.modalBusinessUnit.id, this.modalBusinessUnit)
                : BusinessUnitsService.create(this.modalBusinessUnit)

            return operation
                .then((response) => {
                    this.replaceModalData(response.data.data)
                    return response
                })
                .catch((error) => {
                    this.unsuccessfulOperation()
                    return error
                })
        },

        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const operation = this.isBusinessUnitCreated ? 'edited' : 'created'

            const response = await this.createOrUpdateBusinessUnit()

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.successfulOperation(operation)
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }

            this.submitLoading = false
        },
        successfulOperation(operation) {
            this.$emit('refresh')
            this.resetValidation()
            this.$refs.formModal.hide()
            this.positiveFeedback(this.i18nBusinessUnit, operation)
        },
        unsuccessfulOperation(operation) {
            this.negativeFeedback()
        },
        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteBusinessUnit)
        },
        async deleteBusinessUnit(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            this.$v.modalBusinessUnit.$touch()
            this.deleteLoading = true
            const response = await BusinessUnitsService.delete(this.modalBusinessUnit.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nBusinessUnit, 'deleted', 3)
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },
        async handleRemoveAllPos() {
            this.modalBusinessUnit.point_of_sales = []
        },
        async handleRemovePos(id) {
            const index = this.modalBusinessUnit.point_of_sales.findIndex(find => find.id === id);

            if (index > -1) {
                this.modalBusinessUnit.point_of_sales.splice(index, 1);
            }
        },
        async handleAddUser() {
            this.$v.userModal.$touch()
            if (this.$v.userModal.$invalid || this.submitLoading) {
                return
            }

            const index = this.modalBusinessUnit.users.findIndex(find => find.id === this.userModal.user.id);
            if (index < 0) {
                this.modalBusinessUnit.users.push({
                    id: this.userModal.user.id,
                    name: this.userModal.user.name,
                    email: this.userModal.user.email,
                    monthly_cost: this.userModal.monthly_cost,
                    hours_daily: this.userModal.hours_daily,
                })

                this.userModal = Object.assign(this.userModal, {
                    id: null,
                    name: '',
                    email: '',
                    monthly_cost: 0,
                    hours_daily: 0,
                })

                this.resetValidation()
            }
            this.$refs.userMultiselect.$el.focus()
        },
        async handleRemoveAllUser() {
            this.modalBusinessUnit.users = []
        },
        async handleRemoveUser(id) {
            const index = this.modalBusinessUnit.users.findIndex(find => find.id === id);

            if (index > -1) {
                this.modalBusinessUnit.users.splice(index, 1);
            }
        },
        showPosCsvModal() {
            this.$nextTick(() => this.$refs.posCsvModal.showModal())
        },
        showUserCsvModal() {
            this.$nextTick(() => this.$refs.userCsvModal.showModal())
        },
        async loadPosByJson(csv) {
            const data = await this.$refs.posCsvModal.fetchRelationItems(csv)

            if (data && data.length > 0) {
                this.modalBusinessUnit.point_of_sales = unionBy(this.modalBusinessUnit.point_of_sales, data, 'id')
                this.successFeedback(this.i18nSelectThroughCsv, this.i18nSuccessfullyProcessed)
            } else {
                this.warningFeedback(this.i18nSelectThroughCsv, this.i18nNoRecordsFound)
            }

            this.$refs.posCsvModal.hideModal()
        },
        async loadUsersByJson(csv) {
            const data = await this.$refs.userCsvModal.fetchRelationItems(csv)

            if (data && data.length > 0) {
                this.modalBusinessUnit.users = unionBy(this.modalBusinessUnit.users, data, 'id')
                this.successFeedback(this.i18nSelectThroughCsv, this.i18nSuccessfullyProcessed)
            } else {
                this.warningFeedback(this.i18nSelectThroughCsv, this.i18nNoRecordsFound)
            }

            this.$refs.userCsvModal.hideModal()
        },
    },
}
</script>
